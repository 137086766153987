import React from "react"
import Seo from "../../components/SEO"
import Header from "../../components/Header"
import PageNav from "../../components/PageNav"

export default function GuestBook() {
  return (
    <div>
      <Seo />
      <Header />
      <iframe src="http://sirbook.ruum.info/guestbook" title="SIR Guestbook" width="100%" height="1500px"></iframe>
      <PageNav />
    </div>
  )
}
